import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css'; // Import modal styles

const DatatableStores = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState(null); // Use null as initial state for file
  const [filename, setFilename] = useState("");
  const navigate = useNavigate();
  const web_url = localStorage.getItem('web_url');
  const api_url = localStorage.getItem('api_url');
  const [allSelected, setAllSelected] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(api_url + '/admingetStores');
      const storesData = res.data.stores;
      setData(storesData);
      setFilteredData(storesData);
    };

    fetchData();
  }, [api_url]);

  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const exportSelectedRows = () => {
    const selectedRowsData = selectedRows.map(row => {
      return {
        'Store Name': row.store_name,
        'Address': row.address,
        'Order Count': row.order_count,
        'Dine Status':row.status
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "stores.xlsx");
  };

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   setPic(file);
  //   setFilename(file.name);
  // }; 

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setPic(file);
      setFilename(file.name);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };
  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const handleImport = () => {
    let formData = new FormData();
    formData.append('pic', pic);
    axios.post(api_url + '/importStores', formData)
      .then(res => {
        alert("Stores details imported successfully");
        navigate("/Stores", { replace: true });
        window.location.reload();
      })
      .catch(err => {
        console.error("There was an error importing the file!", err);
      });
  };

  const handleDelete = (store_id) => {
    const choice = window.confirm("Are you sure you want to delete the Store?");
    if (choice) {
      axios.get(api_url + '/deleteStore/' + store_id)
        .then(res => {
          alert("Stores details Deleted successfully");
          navigate("/Stores", { replace: true });
          window.location.reload();
        });
    }
  };

  const columns = [
    {
      name: <input
      type="checkbox"
      onChange={(e) => handleSelectAll(e.target.checked)}
      checked={allSelected}
    />,
      cell: (row) => (
        <input
          type="checkbox"
          onChange={(e) => handleRowSelected(e.target.checked, row)}
          checked={selectedRows.map((selectedRow) => selectedRow.id).includes(row.id)}
        />
      ),
    },
    {
      name: 'Store Name',
      selector: row => row.store_name,
      sortable: true,
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
    },
    {
      name: 'Order Count',
      selector: row => row.order_count,
      sortable: true,
    },
    {
      name: 'Dine Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <button className="tableBtn" onClick={() => handleDelete(row.id)}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pro_buttons">
        <button className="import_tem" onClick={() => setOpenModal(true)}>IMPORT</button>
        <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
      </div>
      <p className="produc_de">Stores</p>
      <div className="data_margin">
        <DataTable
           columns={columns}
           data={filteredData}
           pagination
           paginationPerPage={5}
           paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
           highlightOnHover
           sortServer={false}

           customStyles={{
             headCells: {
               style: {
                 fontWeight: "bold",
               },
             },
             rows: {
               style: {
                 "&:nth-of-type(odd)": {
                   backgroundColor: "#f3f3f3",
                 },
               },
             },
           }}
        />
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)} id="fileupload">
        <div className="container">
          <h3>Import</h3>
          <hr />
          <div className="drop_box">
            { !pic ? (
              <div>
                <header>
                  <h4 className="choose_import">Choose the file to be imported</h4>
                </header>
                <p className="choose_import1">Only csv formats are supported</p>
                <span className="choose_import2">Maximum upload file size is 5MB</span>
                <br />
                <input type="file" hidden accept=".csv" id="fileID" onChange={handleFileUpload} />
                <button className="btn choose_import3" onClick={() => document.getElementById("fileID").click()}>Upload File</button>
                <br />
                <a className="down_sample" href={web_url + "gallery/templates/stores.csv"}>Download sample template for import</a>
              </div>
            ) : (
              <form id="uploadForm" method="post">
                <div className="form">
                  <h4>{filename}</h4>
                  <button className="btn" type="button" onClick={handleImport}>Upload</button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DatatableStores;
