import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link  } from 'react-router-dom';
import {withRouter} from "../components/withRouter"
import '../assets/css/style.css';
import axios from 'axios';

class Login extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',password:'',showPassword: false
    };     
    this.loginfun = this.loginfun.bind(this); 
    this.onChange_email = this.onChange_email.bind(this);    
    this.onChange_password = this.onChange_password.bind(this);  
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);   
  }
  onChange_email(e){    
    this.setState({
        email:e.target.value
    }); 
  }
  onChange_password(e){    
    this.setState({
        password:e.target.value
    }); 
  }
  togglePasswordVisibility() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }
  loginfun(e){
    var signin = new FormData();
    signin.set('email', this.state.email);
    signin.set('password', this.state.password);     


    if(this.state.email){

    
    axios({
      method: 'post',
      url: this.state.api_url+'/adminlogin',
      data: {'email':this.state.email,'password':this.state.password} 
        
            })
    .then(res => {
     
      if(res.data.status == "false"){
        let errors = {};
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'errorMsghide'
                });
              }, 3000);  

      }else{

        if(res.data.admin_id.length === 0){

          let errors = {};
          errors["message"] = "Incorrect Username and Password";
          this.setState({
              errors: errors,
              suc_cls: 'errorMsg floatingAlerts1'
              });
              setTimeout(() => {
                  this.setState({
                      suc_cls: 'errorMsghide'
                  });
                }, 3000);  
          
        }else{
          localStorage.setItem("admin_id",res.data.admin_id[0].id)
          this.props.navigate('/Dashboard');
        }
        
      }
    })

  }
   
  }

  componentDidMount() {
   
    if (!sessionStorage.getItem('hasReloaded')) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    } else {
      sessionStorage.removeItem('hasReloaded');
      this.loginfun();
    }
  
}
  render() {
    return <div className="login">
    <div className="div">
      <div className="group">
        <p className="text-wrapper">Sign in to your account</p>
        <p className="p">
        <div className={this.state.suc_cls}>{this.state.errors.message}</div>
          Thank you get back to Tabemono, Sign in with your email and password if you have a Admin account.
        </p>        
        {/* <h1 className="h-1">Sign in</h1> */}
        <div className="group-2">
          <div className="input-filled">
            <div className="overlap">
            <input 
                    className="input-BG" 
                    id="password" 
                    value={this.state.password} 
                    onChange={this.onChange_password} 
                    type={this.state.showPassword ? "text" : "password"} 
                    maxLength={15} 
                    minLength={6} 
                    placeholder="Password" 
                    required 
                  />
                  <button type="button" className='pass_show' onClick={this.togglePasswordVisibility}>
                    {this.state.showPassword ? "Hide" : "Show"}
                  </button>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
            <input required className="input-BG" type="email" id="email" placeholder="Email" value={this.state.email} onChange={this.onChange_email} />
										
              {/* <div className="input-BG" />
              <div className="placeholder">Email</div> */}
            </div>
          </div>
          <div className="primary">
            {/* <div className="continue"> */}
            
             <button  onClick={this.loginfun}  className="continue" style={{border: "none",background:"#b89d4d"}}>  
             Sign In           
            </button> 
            {/* </div> */}
          </div>
          {/* <div className="text-wrapper-2">Remember me</div>
          <div className="element-forms-elements">
            <img className="ic-check-box" alt="Ic check box"src={require('../assets/img/ic-check-box-24px.svg').default}  />
          </div> */}
        </div>
      </div>
      <img className="left" alt="Left" src={require('../assets/img/foodback.png')} />
      <img  src={require('../assets/img/frame.svg').default} className="frame" alt="Frame" style={{backgroundColor:"antiquewhite",borderRadius:"10px"}}
 />
    </div>
  </div>;
  }
}

export default withRouter(Login);



